// 修改企业信息
import server from "@/utils/https";



// 荣誉资质
export function honorLists() {
    return server({
      url: "policy/honorLists",
    });
}

// 地区数据
export function districts(data) {
    return server({
      url: "policy/districts",
      data
    });
}

// 企业规模
export function sizeLists() {
    return server({
      url: "policy/sizeLists",
    });
}

// 产品类别
export function industryCateLists() {
    return server({
      url: "policy/industryCateLists",
    });
}

// 行业
export function tradeListOne(data) {
    return server({
      url: "policy/tradeLists",
      data
    });
}

// 企业性质
export function natureLists(data) {
    return server({
      url: "policy/natureLists",
      data
    });
}


// 保存修改
export function companyLink(data) {
    return server({
      url: "person/profile",
      data
    });
}


export function matchingv2(data) {
  return server({
    url: "policy/matchingv2",
    data
  });
}


// 企业信息修改
export function companyDetail(data) {
  return server({
    url: "union/companyDetail",
    data
  });
}

// 获取用户信息
export function getUserInform(data) {
  return server({
    url: "user/details",
    data
  });
}
// 修改用户信息
export function changeUserInform(data) {
  return server({
    url: "user/changeDetails",
    data
  });
}