import server from "@/utils/https";

// 联盟列表
export function matrix() {
  return server({
    url: "/union/matrix",
  });
}

//加入我们
export function join(data) {
  return server({
    url: "/union/joinUs",
    data,
  });
}

export function match() {
  return server({
    url: "policy/matchingv2",
  });
}

// 企业列表
export function company() {
  return server({
    url: "union/martixCompany",
  });
}

// 发布活动
export function NoticeCreate(data) {
  return server({
    url: "person/companyNoticeCreate",
    data,
  });
}

//供需发布
export function sadCreatee(data) {
  return server({
    url: "union/sadCreate",
    data,
  });
}

//加入我们列表
export function joinApplyList(data) {
    return server({
      url: "/person/joinApplyList",
      data,
    });
}

//加入申请详情
export function joinApplyDetails(data) {
    return server({
      url: "/person/joinApplyDetails",
      data,
    });
}

//审批加入申请
export function approveJoin(data) {
    return server({
      url: "/person/approveJoin",
      data,
    });
}