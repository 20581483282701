import axios from "axios";
import { ElLoading, ElMessage } from "element-plus";
import router from "@/router";
import { useMainStore } from "@/store";
const server = axios.create({
  baseURL: process.env.VUE_APP_baseURL,
  timeout: 60000,
  method: "post",
});

let loadingInstance;
server.interceptors.request.use(
  (config) => {
    loadingInstance = ElLoading.service();
    const store = useMainStore();
    config.headers.token = store.token || "";
    return config;
  },
  (error) => {
    if (process.env.NODE_ENV === "development") console.log(error);
    return Promise.reject(error);
  }
);

server.interceptors.response.use(
  (response) => {
    loadingInstance.close();
    if (response.data.code !== 1) {
      ElMessage.closeAll();
      ElMessage({
        message: response.data.msg || "网络异常",
        type: "error",
        duration: 5 * 1000,
      });
    }
    return response.data;
  },
  (error) => {
    console.log("22");
    loadingInstance.close();
    ElMessage.closeAll();
    if (process.env.NODE_ENV === "development") console.log(error);
    if (error.response.status === 401) {
      ElMessage.error("请先登录");
      router.replace("/login?backPath=" + location.href);
      return;
    } else {
      if (error.message.indexOf("timeout") != -1) {
        ElMessage.error("网络超时");
      } else if (error.message == "Network Error") {
        ElMessage.error("网络连接错误");
      } else if (error.response) {
        if (error.response.statusText)
          ElMessage.error(error.response.statusText);
      } else {
        ElMessage.error(error);
      }
    }
    return Promise.reject(error);
  }
);

export default server;
