<template>
    <div class="layout-bottom-box">
        <div v-if="route.meta?.showLeftMenu" class="left-menu">
            <LeftMenu :menus="menus"></LeftMenu>
        </div>
        <div class="layout-content">
            <router-view :key="router.currentRoute.value.fullPath"/>
        </div>
    </div>
</template>

<script setup>
import LeftMenu from "@/components/leftMenu"
import { useRoute, useRouter } from "vue-router";
import { defineProps } from "vue";
const route = useRoute();
const router = useRouter();
defineProps({
    menus: {
        type: Array,
        default: () => []
    }
})

</script>
<style lang="less" scoped>
.layout-bottom-box {
    height: calc(100% - 10px);
    display: flex;
    box-sizing: border-box;
    margin: 0 10px;
    >div {
        background-color: #fff;
        height: 100%;
        overflow: auto;
    }
    .left-menu {
        width: 350px;
        border-radius: 10px;
        margin-right: 18px;
        box-shadow: 0px 0px 5px 5px rgba(236, 131, 128, 0.2);
    }
    .layout-content {
        border-radius: 10px;
        box-shadow: 0px 0px 5px 5px rgba(236, 131, 128, 0.2);
        flex: 1;
        margin-bottom: 10px;
    }
    .noMargin {
        margin-top: 0;
    }
}
</style>