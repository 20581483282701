import {
    defineStore
} from "pinia"

export const useMainStore = defineStore({
    id: "user",
    state: () => {
        return {
            token: "",
            link: {},
            userInfo: {},
            qiniutoken: "",
            unionList: [],
        }
    },
    getters: {},
    actions: {
        changeQiniutoken(state, newVal) {
            state.qiniutoken = newVal;
        },
        setToken(token) {
            this.token = token;
        },
        setLink(link) {
            this.link = link;
        },
        setUserInfo(userInfo) {
            this.userInfo = userInfo;
        },
        setUnionList(unionList) {
            this.unionList = unionList;
        }
    },
    persist: {
        enabled: true,
        strategies: [{
            storage: localStorage
        }]
    }
});