import router from "@/router"
import { ElMessage } from "element-plus";
import { useMainStore } from "@/store"
const customClick = (binding) => {
    const store = useMainStore();
    if (store.token) { // 判断条件
        binding.value();
    } else {
        ElMessage({
            message: "尚未登录",
            type: "error",
            duration: 5 * 1000,
        });
        router.replace("/login?backPath=" + location.href)
    }
};

export function directive(app) {
	app.directive('permission',{
        created(el, binding, vnode, prevVnode) {
            el.onclick = () => {
                customClick(binding)
            } 
        }
    });
}