import { createRouter, createWebHashHistory } from "vue-router";
import {
  policyCalcMenu,
  partybuildleadMenu,
  commercialNestMenu,
  otherMenu,
  shareMenu,
  userCenterMenu,
} from "@/config/menu";
import { useMainStore } from "@/store";
import nProgress from "nprogress";
import "nprogress/nprogress.css";
const routes = [
  {
    path: "/",
    component: () => import("@/components/layout/index.vue"),
    redirect: "/home",
    name: "main",
    meta: {
      title: "首页",
    },
    children: [
      {
        path: "home",
        name: "home",
        component: () => import("../views/home/index.vue"),
        meta: {
          noBreadcrumb: true,
          title: "首页",
        },
      },
      {
        path: "policyCalc",
        name: "policyCalc",
        component: () => import("../views/policyCalc/index/index.vue"),
        redirect: "/policyCalc/" + policyCalcMenu[0].path,
        meta: {
          title: "政策计算器",
        },
        children: [...policyCalcMenu],
      },
      {
        path: "commercialNestMenu",
        name: "commercialNestMenu",
        component: () => import("../views/commercialNest/index/index.vue"),
        redirect: "/commercialNestMenu/" + commercialNestMenu[0].path,
        meta: {
          title: "红领筑商巢",
        },
        children: [...commercialNestMenu],
      },
      {
        path: "commercialNestMenu",
        name: "commercialNestMenu",
        component: () => import("../views/commercialNest/index/index.vue"),
        redirect: "/commercialNestMenu/" + commercialNestMenu[0].path,
        meta: {
          title: "红领筑商巢",
          hideSearch: true,
        },
        children: [...commercialNestMenu],
      },
      {
        path: "lead",
        name: "lead",
        component: () => import("@/views/partyBuildLead/index/index.vue"),
        redirect: "/lead/" + partybuildleadMenu[0].path,
        children: [...partybuildleadMenu],
        meta: {
          title: "党建引领",
        },
      },
      {
        path: "other",
        name: "other",
        component: () => import("@/views/other/index/index.vue"),
        redirect: "/other/" + otherMenu[0].path,
        children: [...otherMenu],
        meta: {
          title: "其他应用",
        },
      },
      {
        path: "share",
        name: "share",
        component: () => import("@/views/share/index/index.vue"),
        redirect: "/share/" + shareMenu[0].path,
        children: [...shareMenu],
        meta: {
          title: "共建共享",
        },
      },
      {
        path: "news",
        component: () => import("@/views/news/index/index2.vue"),
        meta: {
          title: "最新动态",
          noBreadcrumb: true,
          hideSearch: true,
        },
        children: [
          {
            path: "",
            name: "news",
            component: () => import("@/views/news/index/index.vue"),
            meta: {
              title: "最新动态",
              noBreadcrumb: true,
              hideSearch: true,
            },
          },
          {
            path: "detail/:id",
            name: "newsDetail",
            meta: {
              title: "详情",
              hideMenu: true,
              showLeftMenu: false,
              noBreadcrumb: true,
              hideSearch: true,
            },
            component: () => import("@/views/partyBuildLead/Detail/index.vue"),
          },
          {
            path: "detail2/:id",
            name: "newsDetail2",
            meta: {
              title: "详情",
              hideMenu: true,
              showLeftMenu: false,
              noBreadcrumb: true,
              hideSearch: true,
            },
            component: () =>
              import("@/views/partyBuildLead/Detail/companyNotice.vue"),
          },
        ],
      },
      {
        path: "trends",
        name: "homeTrends",
        component: () => import("@/views/trends/index/index.vue"),
        meta: {
          title: "供需动态",
          hideSearch: true,
        },
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login/index.vue"),
    meta: {
      noBreadcrumb: true,
      title: "登录",
    },
    beforeEnter: (to, from, next) => {
      const store = useMainStore();
      const unionMenus = JSON.parse(JSON.stringify(store.unionList));
      store.$reset();
      store.setUnionList(unionMenus);
      next();
    },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/register/index.vue"),
    meta: {
      noBreadcrumb: true,
      title: "注册",
    },

    // beforeEnter: (to, from, next) => {
    //   const store = useMainStore();
    //   const unionMenus = JSON.parse(JSON.stringify(store.unionList));
    //   store.$reset();
    //   store.setUnionList(unionMenus);
    //   next();
    // },
  },
  {
    path: "/resetPassword",
    name: "resetPassword",
    component: () => import("@/views/resetPassword/index.vue"),
    meta: {
      noBreadcrumb: true,
      title: "重置密码",
    },

    // beforeEnter: (to, from, next) => {
    //   const store = useMainStore();
    //   const unionMenus = JSON.parse(JSON.stringify(store.unionList));
    //   store.$reset();
    //   store.setUnionList(unionMenus);
    //   next();
    // },
  },
  {
    path: "/userCenter",
    component: () => import("@/components/layout/index.vue"),
    meta: {
      title: "个人中心",
    },
    redirect: "/userCenter",
    children: [
      {
        path: "",
        name: "userCenter",
        component: () => import("../views/userCenter/index/index.vue"),
        meta: {
          title: "个人中心",
          hideSearch: true,
        },
      },
      ...userCenterMenu,
    ],
  },
  {
    path: "/dataCockpit",
    name: "dataCockpit",
    meta: {
      title: "数据驾驶舱",
      icon: "iconfont-hangyedongtai",
    },
    component: () => import("@/views/userCenter/dataCockpit/index.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// const needLoginRoutes = ["userCenter", "join"];
const noNeedLoginRoutes = ["home", "register", "resetPassword"];
router.beforeEach(async (to, from, next) => {
  nProgress.start();
  const store = useMainStore();
  console.log(to.name);
  //   if (needLoginRoutes.find((v) => v === to.name) && !store.token) {
  if (!noNeedLoginRoutes.find((v) => v === to.name) && !store.token) {
    // console.log("111111111111111111111111111");
    if (to.name == "login") {
      next();
    } else {
      router.push("/login?backPath=" + location.href);
    }
  } else {
    next();
  }
});

router.afterEach(() => {
  nProgress.done();
});

export default router;
