<template>
    <el-menu unique-opened :default-openeds="defultOpen" ref="menuRef" @close="select" @open="select"
        :default-active="activeMenu">
        <!-- (store.link.account_type === 3 && v.path == 'addAppeal') -->
        <el-sub-menu
            v-for="(item, index) in menus.filter(v => ( v.path == 'addAppeal') || v.path != 'addAppeal')"
            :key="item" :index="String(index)">
            <template #title><i v-if="item.meta.icon" class="iconfont" :class="item.meta.icon"></i>&nbsp;&nbsp;&nbsp;{{
                item.meta.title }}</template>
            <el-menu-item v-for="(item, index1) in (item.children || []).filter(v => !v.meta.hideMenu)" :key="index1"
                :index="index + '-' + index1" @click="jump(item)">{{ item.meta.title }}</el-menu-item>
        </el-sub-menu>
    </el-menu>
</template>

<script setup>
import { defineProps, watch, ref, nextTick, computed } from "vue"
import { useRoute, useRouter } from "vue-router";
import { useMainStore } from "@/store";
const store = useMainStore();
const router = useRouter();
const route = useRoute();
const props = defineProps({
    menus: {
        type: Array,
        default: () => []
    }
})


const activeMenu = computed(() => {
    if (route.meta.fatherName) {
        return defultOpen.value[0] + '-0'
    } else {
        return ''
    }

})
const jump = (item) => {
    router.push({ name: item.name ? item.name : item.children[0].name })
}
const select = (index) => {
    // console.log(props.menus[index])
    // if(!props.menus[index].children || (props.menus[index].children && props.menus[index].children.filter(v => !v.meta.hideMenu).length == 0)) {
    if (store.link.account_type == 4 && props.menus[index].path == 'addAppeal') {
        // console.log('select')
        // console.log(store.link.account_type);
        jump(props.menus[index].children[1])
        
    } else {
        jump(props.menus[index])
    }
    
    // }
}
const menuRef = ref();
const defultOpen = ref([props.menus.map(v => v.name || v.children[0].name).indexOf(route.name) > -1 ? props.menus.map(v => v.name || v.children[0].name).indexOf(route.name) : props.menus.map(v => v.name || v.children[0].name).indexOf(route.meta.fatherName)].map(v => String(v)))
// const defultOpen = computed(() => {
//     if (props.menus.map(v => v.name || v.children[0].name).indexOf(route.name) > -1) {
//         return [props.menus.map(v => v.name || v.children[0].name).indexOf(route.name)].map(v => String(v))
//     } else {
//         return [props.menus.map(v => v.name || v.children[0].name).indexOf(route.meta.fatherName)].map((v => String(v)))
//     }
// })

watch(() => route.name, () => {
    defultOpen.value = [props.menus.map(v => v.name || v.children[0].name).indexOf(route.name) > -1 ? props.menus.map(v => v.name || v.children[0].name).indexOf(route.name) : props.menus.map(v => v.name || v.children[0].name).indexOf(route.meta.fatherName)].map(v => String(v))
    if (props.menus.map(v => v.name || v.children[0].name).indexOf(route.name) > -1) menuRef.value.open(defultOpen.value)
})
</script>

<style scoped>
.iconfont {
    color: #FA5151;
}
</style>