<template>
    <router-view />
</template>

<style lang="less">
#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: url("@/assets/images/bg.jpg");
    background-size: 100% 100%;
    width: 100vw;
    height: 100vh;
    margin: auto;
    overflow: hidden;
}

.icon, .iconfont {
    font-family: "iconfont" !important;
    font-size: 16px;
}
</style>
