// showLeftMenu 显示左导航
// hideMenu 在左导航中隐藏此菜单
// hideSearch 面包屑右侧搜索隐藏
// noBreadcrumb 隐藏面包屑
import { useMainStore } from "@/store";
import { ElMessage } from "element-plus";
import { getUserInform } from '@/api/changeInformation'
export const policyCalcMenu = [
  {
    path: "seek",
    meta: {
      title: "政策查找",
      icon: "iconfont-shaixuan",
      showLeftMenu: true,
    },
    children: [
      {
        path: "",
        name: "seek",
        meta: {
          title: "政策查找",
          hideMenu: true, //是否显示在左边导航
        },
        component: () => import("../views/policyCalc/seek/index.vue"),
      },
      {
        path: "detail/:id",
        name: "seekDetail",
        meta: {
          title: "详情",
          hideMenu: true,
          showLeftMenu: false,
        },
        component: () => import("../views/policyCalc/detail/index.vue"),
      },
    ],
  },
  {
    path: "calc",
    meta: {
      title: "政策计算",
      icon: "iconfont-jisuanqi",
      showLeftMenu: true,
      hideSearch: true,
    },
    children: [
      {
        path: "",
        name: "calc",
        meta: {
          title: "政策计算",
          hideMenu: true, //是否显示在左边导航
        },
        component: () => import("../views/policyCalc/calc/index.vue"),
      },
      {
        path: "detail/:id",
        name: "calcDetail",
        meta: {
          title: "详情",
          hideMenu: true,
          showLeftMenu: false,
        },
        component: () => import("../views/policyCalc/detail/index.vue"),
      },
    ],
  },
  {
    path: "cash",
    meta: {
      title: "政策兑现",
      icon: "iconfont-a-rongqi54",
      showLeftMenu: true,
      hideSearch: true,
    },
    children: [
      {
        path: "",
        name: "cash",
        meta: {
          title: "政策兑现",
          hideMenu: true, //是否显示在左边导航
        },
        component: () => import("../views/policyCalc/cash/index.vue"),
      },
      {
        path: "detail/:id",
        name: "cashDetail",
        meta: {
          title: "详情",
          hideMenu: true,
          showLeftMenu: false,
          noBreadcrumb: true,
          hideSearch: true,
        },
        component: () => import("../views/policyCalc/cashDetail/index.vue"),
      },
    ],
  },
];

export const commercialNestMenu = [
  {
    path: "service",
    meta: {
      title: "助企服务",
      icon: "iconfont-quanwangshengtaixueyuan_zhaoyangqiketongicon",
      showLeftMenu: true,
    },
    children: [
      {
        path: "",
        name: "service",
        meta: {
          title: "助企服务",
          hideMenu: true, //是否显示在左边导航
        },
        component: () => import("../views/commercialNest/service/index.vue"),
      },
      {
        path: "detail/:id",
        name: "servicetDetail",
        meta: {
          title: "详情",
          hideMenu: true,
          showLeftMenu: false,
          noBreadcrumb: true,
          hideSearch: true,
        },
        component: () =>
          import("@/views/commercialNest/service/components/details"),
      },
    ],
  },
  {
    path: "addAppeal",
    meta: {
      title: "诉求直达",
      icon: "iconfont-cz-sqdj",
      showLeftMenu: true,
    },
    children: [
      {
        path: "",
        name: "addAppeal",
        meta: {
          title: "诉求直达",
          hideMenu: true, //是否显示在左边导航
        },
        beforeEnter: (to, from, next) => {
          const store = useMainStore();
          if (store.link.account_type !== 5) {
            next();
          } else {
            ElMessage({
              message: "普通用户需要加入联盟成为企业",
              type: "warning",
            });
            next(from.path);
          }
        },
        component: () => import("../views/commercialNest/addAppeal/index.vue"),
      },
      {
        path: "appeal",
        name: "appeal",
        meta: {
          title: "全部诉求",
          fatherName: "addAppeal",
        },
        component: () => import("../views/commercialNest/appeal/index.vue"),
      },
      {
        path: "detail",
        name: "appealDetail",
        meta: {
          title: "详情",
          hideMenu: true,
          showLeftMenu: false,
        },
        component: () => import("../views/commercialNest/detail/index.vue"),
      },
    ],
  },
];

export const partybuildleadMenu = [
  {
    path: "overview",
    name: "overview",
    component: () =>
      import("@/components/partyBuildLead/partyBuildOverview.vue"),
    meta: {
      title: "党建概况",
      icon: "iconfont-a-60-zhifaanjian",
      showLeftMenu: true,
      hideSearch: true,
    },
  },
  {
    path: "dynamics",
    meta: {
      title: "动态",
      icon: "iconfont-lilunxuexi",
      showLeftMenu: true,
    },
    children: [
      {
        path: "",
        name: "dynamics",
        meta: {
          title: "动态",
          hideMenu: true, //是否显示在左边导航
        },
        component: () =>
          import("@/components/partyBuildLead/allianceDynamics.vue"),
      },
      {
        path: "detail/:id",
        name: "dynamicsDetail",
        meta: {
          title: "详情",
          hideMenu: true,
          showLeftMenu: false,
          noBreadcrumb: true,
          hideSearch: true,
        },
        component: () => import("@/views/partyBuildLead/Detail/index.vue"),
      },
    ],
  },
  {
    path: "meeting",
    meta: {
      title: "双月例会",
      icon: "iconfont-dongtai",
      showLeftMenu: true,
    },
    children: [
      {
        path: "",
        name: "meeting",
        meta: {
          title: "双月例会",
          hideMenu: true, //是否显示在左边导航
        },
        component: () =>
          import("@/components/partyBuildLead/bimonthlyMeetings.vue"),
      },
      {
        path: "detail/:id",
        name: "meetingDetail",
        meta: {
          title: "详情",
          hideMenu: true,
          showLeftMenu: false,
          noBreadcrumb: true,
          hideSearch: true,
        },
        component: () => import("@/views/partyBuildLead/Detail/index.vue"),
      },
    ],
  },
  {
    path: "answer",
    meta: {
      title: "党务问答",
      icon: "iconfont-wenda",
      showLeftMenu: true,
    },
    children: [
      {
        path: "",
        name: "answer",
        meta: {
          title: "党务问答",
          hideMenu: true, //是否显示在左边导航
        },
        component: () =>
          import("@/components/partyBuildLead/partyAffairsAnswer.vue"),
      },
      {
        path: "detail/:id",
        name: "answerDetail",
        meta: {
          title: "详情",
          hideMenu: true,
          showLeftMenu: false,
          noBreadcrumb: true,
          hideSearch: true,
        },
        component: () =>
          import("@/views/partyBuildLead/Detail/partyAffairs.vue"),
      },
    ],
  },
  {
    path: "notice",
    meta: {
      title: "通知公告",
      icon: "iconfont-huiqizhengce",
      showLeftMenu: true,
    },
    children: [
      {
        path: "",
        name: "notice",
        meta: {
          title: "通知公告",
          hideMenu: true, //是否显示在左边导航
        },
        component: () => import("@/views/partyBuildLead/partyNotice.vue"),
      },
      {
        path: "detail/:id",
        name: "noticeDetail",
        meta: {
          title: "详情",
          hideMenu: true,
          showLeftMenu: false,
          noBreadcrumb: true,
          hideSearch: true,
        },
        component: () =>
          import("@/views/partyBuildLead/Detail/companyNotice.vue"),
      },
    ],
  },
  {
    path: "advocacy",
    meta: {
      title: "政策宣导",
      icon: "iconfont-zhengce2",
      showLeftMenu: true,
    },
    children: [
      {
        path: "",
        name: "advocacy",
        meta: {
          title: "政策宣导",
          hideMenu: true, //是否显示在左边导航
        },
        component: () =>
          import("@/components/partyBuildLead/policyAdvocacy.vue"),
      },
      {
        path: "detail/:id",
        name: "advocacyDetail",
        meta: {
          title: "详情",
          hideMenu: true,
          showLeftMenu: false,
        },
        component: () => import("../views/policyCalc/detail/index.vue"),
      },
    ],
  },
];

export const otherMenu = [
  {
    path: "ranking",
    name: "ranking",
    component: () => import("../views/other/ranking/index.vue"),
    meta: {
      title: "排行榜",
      icon: "iconfont-paixingbang",
      showLeftMenu: true,
      hideSearch: true,
    },
  },
  {
    path: "join",
    name: "join",
    component: () => import("../views/other/join/index.vue"),
    meta: {
      title: "加入我们",
      icon: "iconfont-jiaruwomen1",
      showLeftMenu: true,
      hideSearch: true,
    },
    beforeEnter: (to, from, next) => {
      const store = useMainStore();
      if (store.link.account_type == 5) {
        getUserInform().then(res => {
          if (res.data.userinfo.username && res.data.userinfo.nickname && res.data.userinfo.link_mobile && res.data.userinfo.address && res.data.userinfo.link_man && res.data.userinfo.ident_type) {
            next()
          } else {
            // 请先至个人中心完善信息。
            ElMessage({
              message: "请先至个人中心完善信息",
              type: "warning"
            });
          }
        })
      } else {
        next()
      }
    }
  },
];

export const shareMenu = [
  {
    path: "project",
    meta: {
      title: "项目",
      icon: "iconfont-xiangmu",
      showLeftMenu: true,
      hideSearch: true,
    },
    children: [
      {
        path: "",
        name: "project",
        meta: {
          title: "项目",
          hideMenu: true, //是否显示在左边导航
        },
        component: () => import("../views/share/project/index.vue"),
      },
      {
        path: "supply/:id",
        name: "projectSupply",
        meta: {
          title: "供应详情",
          showLeftMenu: false,
          hideMenu: true,
          noBreadcrumb: true,
          hideSearch: true,
        },
        component: () => import("../views/union/supply/index.vue"),
      },
      {
        path: "demand/:id",
        name: "projectDemand",
        meta: {
          title: "需求详情",
          hideMenu: true, //是否显示在左边导航
          noBreadcrumb: true,
          hideSearch: true,
          showLeftMenu: false,
        },
        component: () => import("../views/union/demand/index.vue"),
      },
    ],
  },
  {
    path: "product",
    meta: {
      title: "产品",
      icon: "iconfont-zhongguohangtiantubiaoheji-weizhuanlunkuo-",
      showLeftMenu: true,
      hideSearch: true,
    },
    children: [
      {
        path: "",
        name: "product",
        meta: {
          title: "产品",
          hideMenu: true, //是否显示在左边导航
        },
        component: () => import("../views/share/product/index.vue"),
      },
      {
        path: "supply/:id",
        name: "productSupply",
        meta: {
          title: "供应详情",
          hideMenu: true, //是否显示在左边导航
          noBreadcrumb: true,
          hideSearch: true,
          showLeftMenu: false,
        },
        component: () => import("../views/union/supply/index.vue"),
      },
      {
        path: "demand/:id",
        name: "productDemand",
        meta: {
          title: "需求详情",
          hideMenu: true, //是否显示在左边导航
          noBreadcrumb: true,
          hideSearch: true,
          showLeftMenu: false,
        },
        component: () => import("../views/union/demand/index.vue"),
      },
      {
        path: "all",
        meta: {
          title: "全部产品",
        },
        children: [
          {
            path: "",
            name: "allproduct",
            meta: {
              title: "全部产品",
              hideMenu: true, //是否显示在左边导航
              fatherName: "product",
            },
            component: () => import("../views/share/allProduct/index.vue"),
          },
          {
            path: "productDetail/:id",
            name: "productDetail",
            meta: {
              title: "详情",
              hideMenu: true, //是否显示在左边导航
              noBreadcrumb: true,
              hideSearch: true,
              showLeftMenu: false,
            },
            component: () => import("../views/share/projectDetail/index.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "activity",
    meta: {
      title: "活动",
      icon: "iconfont-activity-line",
      showLeftMenu: true,
      hideSearch: true,
    },
    children: [
      {
        path: "",
        name: "activity",
        meta: {
          title: "活动",
          hideMenu: true, //是否显示在左边导航
        },
        component: () => import("../views/share/activity/index.vue"),
      },
      {
        path: "supply/:id",
        name: "activitySupply",
        meta: {
          title: "供应详情",
          hideMenu: true, //是否显示在左边导航
          noBreadcrumb: true,
          hideSearch: true,
          showLeftMenu: false,
        },
        component: () => import("../views/union/supply/index.vue"),
      },
      {
        path: "demand/:id",
        name: "activityDemand",
        meta: {
          title: "需求详情",
          hideMenu: true, //是否显示在左边导航
          noBreadcrumb: true,
          hideSearch: true,
          showLeftMenu: false,
        },
        component: () => import("../views/union/demand/index.vue"),
      },
    ],
  },
  {
    path: "site",
    meta: {
      title: "场地",
      icon: "iconfont-changdi",
      showLeftMenu: true,
      hideSearch: true,
    },
    children: [
      {
        path: "",
        name: "site",
        meta: {
          title: "场地",
          hideMenu: true, //是否显示在左边导航
        },
        component: () => import("../views/share/site/index.vue"),
      },
      {
        path: "supply/:id",
        name: "siteSupply",
        meta: {
          title: "供应详情",
          hideMenu: true, //是否显示在左边导航
          noBreadcrumb: true,
          hideSearch: true,
          showLeftMenu: false,
        },
        component: () => import("../views/union/supply/index.vue"),
      },
      {
        path: "demand/:id",
        name: "siteDemand",
        meta: {
          title: "需求详情",
          hideMenu: true, //是否显示在左边导航
          noBreadcrumb: true,
          hideSearch: true,
          showLeftMenu: false,
        },
        component: () => import("../views/union/demand/index.vue"),
      },
    ],
  },
  {
    path: "person",
    meta: {
      title: "人才",
      icon: "iconfont-ren1",
      showLeftMenu: true,
      hideSearch: true,
    },
    children: [
      {
        path: "",
        name: "person",
        meta: {
          title: "人才",
          hideMenu: true, //是否显示在左边导航
        },
        component: () => import("../views/share/person/index.vue"),
      },
      {
        path: "supply/:id",
        name: "personSupply",
        meta: {
          title: "供应详情",
          hideMenu: true, //是否显示在左边导航
          noBreadcrumb: true,
          hideSearch: true,
          showLeftMenu: false,
        },
        component: () => import("../views/union/supply/index.vue"),
      },
      {
        path: "demand/:id",
        name: "personDemand",
        meta: {
          title: "需求详情",
          hideMenu: true, //是否显示在左边导航
          noBreadcrumb: true,
          hideSearch: true,
          showLeftMenu: false,
        },
        component: () => import("../views/union/demand/index.vue"),
      },
    ],
  },
  {
    path: "teachers",
    meta: {
      title: "师资",
      icon: "iconfont-ziyuan",
      showLeftMenu: true,
      hideSearch: true,
    },
    children: [
      {
        path: "",
        name: "teachers",
        meta: {
          title: "师资",
          hideMenu: true, //是否显示在左边导航
        },
        component: () => import("../views/share/teachers/index.vue"),
      },
      {
        path: "supply/:id",
        name: "teachersSupply",
        meta: {
          title: "供应详情",
          hideMenu: true, //是否显示在左边导航
          noBreadcrumb: true,
          hideSearch: true,
          showLeftMenu: false,
        },
        component: () => import("../views/union/supply/index.vue"),
      },
      {
        path: "demand/:id",
        name: "teachersDemand",
        meta: {
          title: "需求详情",
          hideMenu: true, //是否显示在左边导航
          noBreadcrumb: true,
          hideSearch: true,
          showLeftMenu: false,
        },
        component: () => import("../views/union/demand/index.vue"),
      },
    ],
  },
];

export const userCenterMenu = [
  {
    path: "myActivity",
    meta: {
      title: "发布活动",
      icon: "iconfont-shaixuan",
      showLeftMenu: true,
      noBreadcrumb: true,
      hideSearch: true,
    },
    children: [
      {
        path: "",
        name: "myActivity",
        meta: {
          title: "发布活动",
          hideMenu: true, //是否显示在左边导航
        },
        component: () => import("../views/userCenter/myActivity/index.vue"),
      },
      {
        path: "publish",
        name: "publish",
        meta: {
          title: "发布活动",
          hideMenu: true, //是否显示在左边导航
        },
        component: () => import("../views/userCenter/myActivity/publish.vue"),
      },
    ],
  },
  {
    path: "noticeRecord",
    meta: {
      title: "通知记录",
      icon: "iconfont-shaixuan",
      showLeftMenu: true,
      noBreadcrumb: true,
      hideSearch: true,
    },
    children: [
      {
        path: "",
        name: "noticeRecord",
        meta: {
          title: "通知记录",
          hideMenu: true,
        },
        component: () => import("../views/userCenter/noticeRecord/index.vue"),
      },
      {
        path: "detail/:id",
        name: "noticeRecordDetail",
        meta: {
          title: "详情",
        },
        component: () => import("../views/userCenter/activityDetail/index.vue"),
      },
    ],
  },
  {
    path: "activityRecord",
    meta: {
      title: "报名记录",
      icon: "iconfont-shaixuan",
      showLeftMenu: true,
      noBreadcrumb: true,
      hideSearch: true,
    },
    children: [
      {
        path: "",
        name: "activityRecord",
        meta: {
          title: "报名记录",
          hideMenu: true, //是否显示在左边导航
        },
        component: () => import("../views/userCenter/activityRecord/index.vue"),
      },
      {
        path: "detail/:id",
        name: "activityRecordDetail",
        meta: {
          title: "详情",
          hideMenu: true,
          showLeftMenu: false,
        },
        component: () => import("../views/userCenter/activityDetail/index.vue"),
      },
    ],
  },
  {
    path: "sign",
    meta: {
      title: "我要报名",
      icon: "iconfont-shaixuan",
      showLeftMenu: true,
      noBreadcrumb: true,
      hideSearch: true,
    },
    children: [
      {
        path: "",
        name: "sign",
        meta: {
          title: "我要报名",
          hideMenu: true, //是否显示在左边导航
        },
        component: () => import("../views/userCenter/activityRecord/index.vue"),
      },
      {
        path: "detail",
        name: "signDetail",
        meta: {
          title: "详情",
          hideMenu: true,
          showLeftMenu: false,
        },
        component: () => import("../views/userCenter/activityDetail/index.vue"),
      },
    ],
  },
  {
    path: "notificationInvitation",
    meta: {
      title: "通知邀请",
      icon: "iconfont-shaixuan",
      showLeftMenu: true,
      noBreadcrumb: true,
      hideSearch: true,
    },
    children: [
      {
        path: "",
        name: "notificationInvitation",
        meta: {
          title: "通知邀请",
          hideMenu: true, //是否显示在左边导航
        },
        component: () =>
          import("../views/userCenter/notificationInvitation/index.vue"),
      },
      {
        path: "detail/:id",
        name: "notificationInvitationDetail",
        meta: {
          title: "详情",
          hideMenu: true,
          showLeftMenu: false,
        },
        component: () =>
          import("../views/userCenter/noticeRecord/Detail/index.vue"),
      },
    ],
  },
  {
    path: "checkRecords",
    meta: {
      title: "查收记录",
      icon: "iconfont-shaixuan",
      showLeftMenu: true,
      noBreadcrumb: true,
      hideSearch: true,
    },
    children: [
      {
        path: "",
        name: "checkRecords",
        meta: {
          title: "查收记录",
          hideMenu: true, //是否显示在左边导航
        },
        component: () =>
          import("../views/userCenter/notificationInvitation/index.vue"),
      },
      {
        path: "detail/:id",
        name: "checkRecordsDetail",
        meta: {
          title: "详情",
          hideMenu: true,
          showLeftMenu: false,
        },
        component: () => import("../views/userCenter/activityDetail/index.vue"),
      },
    ],
  },
  {
    path: "claimHandling",
    meta: {
      title: "诉求记录",
      icon: "iconfont-shaixuan",
      showLeftMenu: true,
    },
    children: [
      {
        path: "",
        name: "claimHandling",
        meta: {
          title: "诉求记录",
          hideMenu: true, //是否显示在左边导航
          hideSearch: true,
        },
        component: () => import("../views/userCenter/claimHandling/index.vue"),
      },
    ],
  },
  {
    path: "appealRecord",
    meta: {
      title: "诉求记录",
      icon: "iconfont-shaixuan",
      showLeftMenu: true,
    },
    children: [
      {
        path: "",
        name: "appealRecord",
        meta: {
          title: "诉求记录",
          hideMenu: true, //是否显示在左边导航
          hideSearch: true,
        },
        component: () => import("../views/userCenter/appealRecord/index.vue"),
      },
    ],
  },
  {
    path: "policyRecord",
    meta: {
      title: "政策匹配记录",
      icon: "iconfont-shaixuan",
      showLeftMenu: true,
    },
    children: [
      {
        path: "",
        name: "policyRecord",
        meta: {
          title: "政策匹配记录",
          hideMenu: true, //是否显示在左边导航
          hideSearch: true,
        },
        component: () => import("../views/userCenter/policyRecord/index.vue"),
      },
      {
        path: "detail/:id",
        name: "policyRecordDetail",
        meta: {
          title: "详情",
          hideMenu: true,
          showLeftMenu: false,
          hideSearch: true,
        },
        component: () =>
          import("../views/userCenter/policyRecordDetail/index.vue"),
      },
    ],
  },
  {
    path: "select",
    name: "select",
    meta: {
      title: "企业信息修改",
      icon: "iconfont-jisuanqi",
      showLeftMenu: true,
      hideSearch: true,
    },
    component: () => import("../views/userCenter/select/index.vue"),
  },
  {
    path: "refineInformation",
    name: "refineInformation",
    meta: {
      title: "个人信息完善",
      icon: "iconfont-jisuanqi",
      showLeftMenu: true,
      hideSearch: true,
    },
    component: () => import("../views/userCenter/refineInformation/index.vue"),
  },
  {
    path: "trends",
    meta: {
      title: "动态",
      icon: "iconfont-a-rongqi54",
      showLeftMenu: true,
      hideSearch: true,
    },
    children: [
      {
        path: "",
        name: "trends",
        meta: {
          title: "动态",
          hideMenu: true, //是否显示在左边导航
        },
        component: () => import("../views/userCenter/trends/index.vue"),
      },
      {
        path: "detail/:id",
        name: "trendsDetail",
        meta: {
          title: "详情",
          hideMenu: true,
          showLeftMenu: false,
          noBreadcrumb: true,
          hideSearch: true,
        },
        component: () => import("../views/userCenter/cashDetail/index.vue"),
      },
    ],
  },
  {
    path: "noticeAnnouncement",
    meta: {
      title: "通知公告",
      icon: "iconfont-a-rongqi54",
      showLeftMenu: true,
      hideSearch: true,
    },
    children: [
      {
        path: "",
        name: "noticeAnnouncement",
        meta: {
          title: "通知公告",
          hideMenu: true, //是否显示在左边导航
        },
        component: () =>
          import("../views/userCenter/noticeAnnouncement/index.vue"),
      },
      {
        path: "detail/:id",
        name: "noticeAnnouncementDetail",
        meta: {
          title: "详情",
          hideMenu: true,
          showLeftMenu: false,
          noBreadcrumb: true,
          hideSearch: true,
        },
        component: () => import("../views/userCenter/activityDetail/index.vue"),
      },
    ],
  },
  {
    path: "share",
    name: "userCenterShare",
    component: () => import("../views/userCenter/share/index.vue"),
    meta: {
      title: "共建共享",
      icon: "iconfont-jisuanqi",
      showLeftMenu: true,
      hideSearch: true,
    },
    beforeEnter: (to, from, next) => {
      const store = useMainStore();
      if (store.link.account_type == 5) {
        getUserInform().then(res => {
          if (res.data.userinfo.username && res.data.userinfo.nickname && res.data.userinfo.link_mobile && res.data.userinfo.address && res.data.userinfo.link_man && res.data.userinfo.ident_type) {
            next()
          } else {
            // 请先至个人中心完善信息。
            ElMessage({
              message: "请先至个人中心完善信息",
              type: "warning"
            });
          }
        })
      } else {
        next()
      }
    }
  },
  {
    path: "enterpriseAssistanceServices",
    meta: {
      title: "助企服务",
      icon: "iconfont-a-rongqi54",
      showLeftMenu: true,
      hideSearch: true,
    },
    children: [
      {
        path: "",
        name: "enterpriseAssistanceServices",
        meta: {
          title: "助企服务",
          hideMenu: true, //是否显示在左边导航
        },
        component: () =>
          import("../views/userCenter/departmentTable/index.vue"),
      },
      {
        path: "detail/:id",
        name: "enterpriseAssistanceServicesDetail",
        meta: {
          title: "详情",
          hideMenu: true,
          showLeftMenu: false,
          noBreadcrumb: true,
          hideSearch: true,
        },
        component: () => import("../views/userCenter/cashDetail/index.vue"),
      },
    ],
  },
  {
    path: "policyImplementation",
    meta: {
      title: "政策兑现",
      icon: "iconfont-a-rongqi54",
      showLeftMenu: true,
      hideSearch: true,
    },
    children: [
      {
        path: "",
        name: "policyImplementation",
        meta: {
          title: "政策兑现",
          hideMenu: true, //是否显示在左边导航
        },
        component: () =>
          import("../views/userCenter/departmentTable/index.vue"),
      },
      {
        path: "detail/:id",
        name: "policyImplementationDetail",
        meta: {
          title: "详情",
          hideMenu: true,
          showLeftMenu: false,
          noBreadcrumb: true,
          hideSearch: true,
        },
        component: () => import("../views/userCenter/cashDetail/index.vue"),
      },
    ],
  },
  {
    path: "policyReleaseRecord",
    meta: {
      title: "政策发布记录",
      icon: "iconfont-a-rongqi54",
      showLeftMenu: true,
      hideSearch: true,
    },
    children: [
      {
        path: "",
        name: "policyReleaseRecord",
        meta: {
          title: "政策发布记录",
          hideMenu: true, //是否显示在左边导航
        },
        component: () =>
          import("../views/userCenter/departmentTable/index.vue"),
      },
      {
        path: "detail/:id",
        name: "policyReleaseRecordDetail",
        meta: {
          title: "详情",
          hideMenu: true,
          showLeftMenu: false,
          hideSearch: true,
        },
        component: () =>
          import("../views/userCenter/policyRecordDetail/index.vue"),
      },
    ],
  },
  {
    path: "userCenterSupply",
    name: "userCenterSupply",
    component: () => import("@/views/userCenter/supplyAndDemandList/index.vue"),
    meta: {
      title: "供需列表",
      icon: "iconfont-jisuanqi",
      showLeftMenu: true,
      hideSearch: true,
    },
  },
];
